import { createRouter, createWebHistory } from 'vue-router'
import ImagesPage from './pages/ImagesPage.vue'
import InicioPage from './pages/InicioPage.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", redirect: "/home" },
    { path: "/home", component: InicioPage },
    { path: "/collage", component: ImagesPage },
    { path: '/:notFound(.*)', redirect: "/" }
  ],
});


export default router;