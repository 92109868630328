<template>
      <router-view v-slot="{ Component }">
        <transition name="route" mode="out-in">
          <component :is="Component"></component>
        </transition>
      </router-view>
</template>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  background-color: #2c3e50;
  color: aliceblue;
  margin-top: 0px;
}
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
body{
  background-color: #2c3e50;
}
.page {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
  
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
}
/* sc btn */
/* SportsCam Button */
.sc-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 40px;
  background: #1975a6;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.sc-btn span {
  position: relative;
  z-index: 2;
  transition: .3s ease all;
}

.sc-btn::after {
  content: "";
  width: 100%;
  height: 100%;
  background: #08364d;
  position: absolute;
  z-index: 1;
  top: -40px;
  left: 0;
  transition: .3s ease-in-out all;
}

.sc-btn:hover::after {
  top: 0;
}
/* SportsCam Input */
.sc-input {
  width: 300px;
  height: 40px;
  border: none;
  border-radius: 5px;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
}
button,
input {
  overflow: visible;
}

button {
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-indent: 0px;
  text-shadow: none;
  align-items: flex-start;
  cursor: default;
  box-sizing: border-box;
}

</style>
