<template>
  <div class="container">
    <div class="wall">
      <div class="photo"
        v-for="(image, imageIndex) in images"
        :key="imageIndex"
        :style="{
          backgroundImage: 'url(' + image.image_url + ')',
          height: '200px',
        }"
      ></div>
    </div>

    <div class="featured">
        <img
          :src="superImage"
          alt=""
        />
    </div>
</div>

  

</template>
 
<script>
import axios from "axios";
import _ from 'lodash'

export default {
  data() {
    return {
      images: [],
      images2:[],
      superImage: "",
      index: null,
      polling: null,
      polling2: null,

    };
  },
  created() {
    this.loadImages()
    this.pollData()
    this.pollSuperImage()
  },

  methods: {
    loadImages() {
      const url = `${process.env.VUE_APP_COLLAGE_API}/images`;
      axios
        .get(url)
        .then((res) => {
          this.images2 = res.data;
          var extra = _.differenceWith(this.images2, this.images, _.isEqual)
          this.images = _.concat(this.images, extra);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    pollData() {
      this.polling = setInterval(() => {
        this.loadImages()
        console.log(this.images)
        this.images = _.shuffle(this.images)
		}, 60000)
    },

    pollSuperImage(){
      this.polling2 = setInterval(() => {
        var i = Math.floor(Math.random() * this.images.length)
        this.superImage = this.images[i].image_url
		}, 5000)
    }
  },
};
</script> 
 
<style scoped>
body {
  background-color: aliceblue;
  height: 100%;
  width: 100%;
  margin: 0;
}

.container {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.wall {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  width: 100%;
  height: 100%;
}

.photo {
  background-size: cover;
  background-position: center;
  width: '300px';
  height: '200px';
}

.featured {
  z-index: 9;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.featured>img {
  background-color: white;
  padding: 20px;
  height: 80vh;
}

@keyframes showDiv {
   0%  {width: 0px; height: 0px; transform: translate(0%);}
  25%  {width: 500px; height: 500px; transform: translate(50%,15%);}
  75%  {width: 0px; height: 0px; transform: translate(50%,15%);}
  100% {width: 0px; height: 0px; transform: translate(0%);}
}
@keyframes showImage{
   0%  {width: 0px; height: 0px;}
  25%  {width: 400px; height: 400px;}
  75%  {width: 0px; height: 0px;}
  100% {width: 0px; height: 0px;}
}
</style> 
 